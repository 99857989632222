<script>
import { mapActions, mapState } from 'vuex';
import dayjs from 'dayjs';

export default {
  data () {
    return {
    }
  },
  computed: {
    ...mapState('loyalty', {
      points: (state) => state.points,
      loyaltyCustomer: (state) => state.customer,
    }),
  },
  methods: {
    ...mapActions('loyalty', [
      'getLoyaltyPoints',
    ]),
    formatDateTime(dt) {
      return dayjs(dt).format('DD MMM, HH:mm');
    },
    close() {
      this.$router.push({ name: 'profile' });
    }
  },
  async mounted() {
    this.getLoyaltyPoints({});
  }
}
</script>
<template>
  <v-card class="point-list" color="#f7efe8" elevation="0">
    <v-toolbar
      color="#e1cfc7"
      dense
    >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-toolbar-title
        class="text-subtitle-2 font-weight-6 pr-7"
      >
        Point History
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        class="mr-1"
        icon
        x-small
        @click="close()"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-list class="px-4" color="#f7efe8" two-line>
      <template v-for="(point, index) in points">
        <v-list-item :key="point.id">
          <v-list-item-content>
            <v-list-item-subtitle
              class="text-small font-weight-5"
              style="letter-spacing:0.10rem !important;"
            >{{ formatDateTime(point.createdAt) }}</v-list-item-subtitle>

            <v-list-item-title
              class="text-caption font-weight-6 mt-1"
              style="letter-spacing:0.12rem !important;"
            >{{ point.type }}</v-list-item-title>

            <v-list-item-subtitle></v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-action>
            <v-list-item-action-text class="text-subtitle-1">
              <span class="font-weight-6">{{ point.points }}</span> <span class="text-small font-weight-5" style="letter-spacing:0.12rem !important;">pts</span>
            </v-list-item-action-text>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          class="my-0 py-0"
          :key="index"
          v-if="index < points.length - 1"
        ></v-divider>
      </template>
    </v-list>
  </v-card>
</template>
<style scoped>
.point-list {
  background-color: #f7efe8;
  min-height: 100dvh;
}
</style>